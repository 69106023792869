<app-header [imageLinkable]="false"></app-header>

<div class="bolt-container pb-10">
  <!-- moved from admin-page -->
  <div class="bolt-row pt-5" *ngIf="isSuccess">
    <div class="bolt-col">
      <bolt-notification type="success">The device has been updated successfully.</bolt-notification>
    </div>
  </div>

  <div class="pt-4" id="no-data-notification" *ngIf="isNoData">
    <bolt-notification type="error">
      Unable to retrieve data at this time. Please try again.
    </bolt-notification>
  </div>

  <div class="bolt-row pt-5 pb-5">
    <div class="bolt-col">
      <span class="font-size-xl heading">Smart home device management tool</span>
    </div>
  </div>
  <div class="bolt-row pb-5">
    <div class="bolt-col">
      <span>You can view and edit the number of free devices remaining and cost per device for different
        partners.</span>
    </div>
  </div>
  <!-- moved from admin-page -->
  <div class="container-bar">
    <p *ngIf="!isNoData" class="d-inline date-text">Last updated: {{ lastUpdateTs | date: 'MM/dd/yyyy' }}</p>
    <bolt-button *ngIf="!isNoData" size="sm" iconright="download" (click)="exportToExcel()"
      class="p-0 d-inline download-button">
      Download table
    </bolt-button>
  </div>
  <bolt-waiting-indicator *ngIf="isLoading">Loading...</bolt-waiting-indicator>
  <div class="bolt-row m-auto" *ngIf="!isLoading">
    <div class="bolt-col p-0">

      <div class="no-data" *ngIf="isNoData" id="no-data-message">
        <p class="no-data-text">Unable to retrieve data at this time. Please try again.</p>
      </div>

      <div id="data-table" class="bolt-table bolt-table-vibrant-blue">
        <table #table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource"
          (matSortChange)="sortData($event)" matSort>
          <ng-container matColumnDef="state">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell">State</th>
            <td mat-cell *matCellDef="let element">{{element?.state}}</td>
          </ng-container>
          <ng-container matColumnDef="policyType">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell">Policy</th>
            <td mat-cell *matCellDef="let element">{{element.policyType}}</td>
          </ng-container>
          <ng-container matColumnDef="coverageALimit">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell">Coverage A Below
            </th>
            <td mat-cell *matCellDef="let element">{{element.coverageALimit | currency:'USD': 'symbol':'1.0-0' }}</td>
          </ng-container>
          <ng-container matColumnDef="deviceType">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell">Device Type 1
            </th>
            <td mat-cell *matCellDef="let element">{{element.deviceType}}</td>
          </ng-container>
          <ng-container matColumnDef="vendor">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell">Device Vendor 1
            </th>
            <td mat-cell *matCellDef="let element">{{element.vendor}}</td>
          </ng-container>
          <ng-container matColumnDef="deviceOffer">
            <th mat-header-cell mat-sort-header mat-sort-header *matHeaderCellDef class="table-header-cell">
              Device Offer 1</th>
            <td mat-cell *matCellDef="let element">{{element.deviceOffer}}</td>
          </ng-container>
          <ng-container matColumnDef="deviceSavingsPct">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell">Device Savings 1
            </th>
            <td mat-cell *matCellDef="let element">{{formatPercentage(element.deviceSavingsPct)}}</td>
          </ng-container>
          <ng-container matColumnDef="deviceType2">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell">Device Type 2
            </th>
            <td mat-cell *matCellDef="let element">{{element.deviceType2}}</td>
          </ng-container>
          <ng-container matColumnDef="vendor2">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell">Device Vendor 2
            </th>
            <td mat-cell *matCellDef="let element">{{element.vendor2}}</td>
          </ng-container>
          <ng-container matColumnDef="deviceOffer2">
            <th mat-header-cell mat-sort-header mat-sort-header *matHeaderCellDef class="table-header-cell">
              Device Offer 2</th>
            <td mat-cell *matCellDef="let element">{{element.deviceOffer2}}</td>
          </ng-container>
          <ng-container matColumnDef="deviceSavingsPct2">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-header-cell">Device Savings 2
            </th>
            <td mat-cell *matCellDef="let element">{{element.deviceSavingsPct2}}</td>
          </ng-container>
          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button [matMenuTriggerFor]="appMenu" class="menu-icon">
                <mat-icon class="icon-view">more_horiz</mat-icon>
              </button>
              <mat-menu #appMenu="matMenu" [overlapTrigger]="false" class="option-container">
                <button mat-menu-item (click)="onEditClick(element)">Edit</button>
                <button mat-menu-item (click)="openDuplicateModal(element)">Duplicate</button>
                <button mat-menu-item (click)="openDeleteModel(element)">Delete</button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="paginator-section" id="paginator" [hidden]="isLoading">
    <div class="pageSizeContainer">
      <p class="pageSizeLabel">Items per page</p>
      <button [ngClass]="is25PageSizeButtonDisabled ? 'pageSizeButtonDisabled' : 'pageSizeButton'"
        (click)="setFirstPageSize()">25</button>
      <button [ngClass]="is50PageSizeButtonDisabled ? 'pageSizeButtonDisabled' : 'pageSizeButton'"
        (click)="setSecondPageSize()">50</button>
      <button [ngClass]="is100PageSizeButtonDisabled ? 'pageSizeButtonDisabled' : 'pageSizeButton'"
        (click)="setThirdPageSize()">100</button>
    </div>
    <div class="bolt-col-md-auto">
      <mat-paginator paginatorPageNumberButtonDirective [recordsLength]="totalRecords" [length]="totalRecords"
        [pageSize]="pageSize" [pageIndex]="pageIndex"></mat-paginator>
    </div>
    <div>
      <p class="goToPageText">Go to page</p>
      <input type="number" class="goToInput" [(ngModel)]="pageNum">
      <bolt-button size="sm" (click)="goToPage()">Go</bolt-button>
    </div>
  </div>
</div>
<bolt-modal id="deleteModal" #deleteModalRef type="question" heading="Are you sure you want to delete this device?"
  primarybutton="Delete Device" secondarybutton="Cancel" (bolt-modal-primary-button-click)="deleteDevice()"
  (bolt-modal-secondary-button-click)="cancelDelete()">
  <p>Deleting the device will delete the device and it’s all data from the database. You cannot undo this action.</p>
</bolt-modal>

<bolt-modal id="duplicateModal" #duplicateModalRef type="question"
  heading="Are you sure you want to duplicate this row?">
  <p>Duplicating the row will not create a new device in the database, you will need to edit the details, review and
    save the
    duplicated device to create a new item.</p>
  <bolt-button-bar slot="modal-button-bar">
    <bolt-button slot="forward" type="primary" (click)="editDuplicateRow()">Duplicate Row</bolt-button>
    <bolt-button slot="cancel" onclick="window.duplicateModal.closeModal()">Close</bolt-button>
  </bolt-button-bar>
</bolt-modal>